// extracted by mini-css-extract-plugin
export var awardCard = "cT_wd";
export var awardCard__bdg_large = "cT_wj";
export var awardCard__bdg_small = "cT_wk";
export var awardCard__container = "cT_wq";
export var awardCard__description = "cT_wp";
export var awardCard__info = "cT_wl";
export var awardCard__large = "cT_wg";
export var awardCard__link = "cT_wn";
export var awardCard__post = "cT_wf";
export var awardCard__small = "cT_wh";
export var awardCard__title = "cT_wm";